import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import "./ThankYou.scss";
import { Button } from '@material-ui/core';
import {
    IonRow,
    IonCol,
    IonButton,
    IonImg
} from '@ionic/react';
import webtitleImg from '../../assets/images/brand/title.png';
import mobtitleImg from '../../assets/images/brand/title.png';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import welcometitle_logo from '../../assets/images/b2cbanner/welcometitle.png';
import SportsBetting from '../../assets/images/faricimage/SportsBetting.png';
import x7Support from '../../assets/images/faricimage/24x7Support.png';
import LiveCasino from '../../assets/images/faricimage/LiveCasino.png';
import bankicon from '../../assets/images/faricimage/bank.png';
import googlepayicon from '../../assets/images/faricimage/googlepay.png';
import paytmicon from '../../assets/images/faricimage/paytm.png';
import Phonepeicon from '../../assets/images/faricimage/Phonepe.png';
import UPIicon from '../../assets/images/faricimage/UPI.png';
import VirtualSports from '../../assets/images/faricimage/VirtualSports.png';

import Bonus200 from '../../assets/images/faricimage/Bonus200.png';
import OnSignUp from '../../assets/images/faricimage/OnSignup100.png';
import { toast } from 'react-toastify';

const ThankYou: React.FC = (props) => {
    const history = useHistory();

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Text Copied!");
      }

    return (
        <div className="thank-you-ctn">
            <div className="welcome-card">
                <div className='wel-item-ctn'>
                    <IonRow >
                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
                            <div className='welcome_logo'>
                                <IonImg className="web-view" src={webtitleImg} />
                                <IonImg className="mob-view" src={mobtitleImg} />
                            </div>
                        </IonCol>
                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
                            <div className='text1_align text-light'>PLAY THE GAME OF WINNING</div>
                        </IonCol>
                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
                            <div className='text_align_WelBonus text-green'>Thank you for signing-up!</div>
                        </IonCol>
                        
                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen mrl25'>
                            <IonRow>
                                <IonImg className='w-90 pb-14' src={OnSignUp} onClick={() => copyText("WIN100")} />
                            </IonRow>
                            <IonRow>
                                <IonImg className='w-90' src={Bonus200} onClick={() => copyText("FIRSTDEPOSIT")} />
                            </IonRow>
                        </IonCol>

                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
                            <div className='text_align_WelBonus text-light'>WhatsApp us the promo code.</div>
                        </IonCol>

                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
                            <IonButton className="my-bets-btn withdraw-btn actions-btn claim-btn-fair" 
                                onClick={() => history.replace('/login')}>
                                Claim Now
                            </IonButton>
                        </IonCol>

                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
                            <div className='text1_align'>Available on</div>
                        </IonCol>
                        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen payment-method'>
                            <div className='payment-icons-block'>
                                <img src={bankicon} className='icon_bank_align' />
                                <img src={paytmicon} className='icon_paytm_align' />
                                <img src={UPIicon} className='icon_upi_align' />
                                <img src={Phonepeicon} className='icon_phonepe_align' />
                                <img src={googlepayicon} className='icon_googleplay_align' />

                            </div>
                        </IonCol>
                    </IonRow>
                </div>
            </div>
        </div>
    )
}

export default ThankYou;